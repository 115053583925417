
// Asynchronous component definitions for lazy-loading routes.
// https://v3.router.vuejs.org/guide/advanced/lazy-loading.html
// https://webpack.js.org/guides/code-splitting/#dynamic-imports
// https://webpack.js.org/api/module-methods/#magic-comments
const
    GamePage = async () => import(
        /* webpackChunkName: 'game' */
        '../views/GamePage.vue'
    );

export default [
    {
        path: '/game',
        component: GamePage,
    },
];
