
import store from '../store/index';

// Asynchronous component definitions for lazy-loading routes.
// https://v3.router.vuejs.org/guide/advanced/lazy-loading.html
// https://webpack.js.org/guides/code-splitting/#dynamic-imports
// https://webpack.js.org/api/module-methods/#magic-comments
const
    DuplicateAccountPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/DuplicateAccountPage.vue'
    ),
    LimiterPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/LimiterPage.vue'
    ),
    LoginPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/LoginPage.vue'
    ),
    RegisterPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/RegisterPage.vue'
    ),
    WelcomePage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/WelcomePage.vue'
    );

export default [
    { path: '/', redirect: (to) => {
        // Used to send server-side errors to the '/error' page.
        if (store.state.app.defaultRoute) {
            return store.state.app.defaultRoute;
        }
        // Default routes for logged-in users.
        else if (
            store.getters['profile/loggedIn'] &&
            !store.getters['profile/isSessionExpired']
        ) {
            console.log('logged in');

            if (store.state.profile?.isUploadLimited) {
                return '/limiter';
            }
            else {
                return '/welcome';
            }
        }
        // Default routes for returning users (reg cookie) who are not logged-in.
        else if (store.getters['profile/returning']) {
            return '/login';
        }

        // Default route for a user we know nothing about.
        return '/register';
    } },
    { path: '/login', component: LoginPage, meta: { public: true } },
    { path: '/register', component: RegisterPage, meta: { public: true } },
    { path: '/limiter', component: LimiterPage },
    { path: '/dup_account', component: DuplicateAccountPage, meta: { public: true } },
    {
        path: '/welcome',
        component: WelcomePage,
        beforeEnter: async (to, from, next) => {
            // Check the limit before entering the welcome page. The logic here will duplicate the limit
            // check in instances where the user was redirected to the 'welcome' page from the '/' path.
            // But this will ensure the limit is enforced if the user refreshes or goes to the welcome page directly.
            await store.dispatch('profile/checkUploadLimited');
            if (store.state.profile.isUploadLimited) {
                next('/limiter');
            }
            else {
                next();
            }
        },
    },
];
